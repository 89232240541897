import styled from '@emotion/styled';

const ImageWrapper = styled('div')({
  borderRadius: '0.8rem',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
  overflow: 'hidden',
  position: 'relative',
});

export default ImageWrapper;
